import axios from "axios";
import i18next from "i18next";

axios.defaults.baseURL = "http://64.226.76.165/api";
axios.interceptors.request.use(
  (config) => {
    config.headers["Accept-Language"] = i18next.language;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
