export const base_url = "http://64.226.76.165";

export const paymentPeriodOptions = [
  {
    value: 6,
    label: 6,
  },
  {
    value: 12,
    label: 12,
  },
  {
    value: 18,
    label: 18,
  },
  {
    value: 24,
    label: 24,
  },
  {
    value: 30,
    label: 30,
  },
  {
    value: 36,
    label: 36,
  },
  {
    value: 48,
    label: 48,
  },
  {
    value: 60,
    label: 60,
  },
];
